import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { media } from "../styles/theme"
import { getProjectLink } from "../urls"

const S = {}

S.ImagesList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1rem 1rem;

  ${media.tablet`
    gap: 2rem 2rem;
    flex: 50%;
  `}
`

S.ProjectListItem = styled.div`
  flex: auto;
  position: relative;
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;

  ${media.tablet`
    flex: 30%;
  `}
`

S.ImageWrapper = styled.div`
  transition: all ${props => props.theme.transition} ease-in-out;
  filter: brightness(0.5);

  ${media.laptop`
    filter: brightness(1);
    transition: all ${props => props.theme.transition} ease-in-out;
    ${S.ProjectListItem}:hover & {
      filter: brightness(0.5);
    }
  `}
`

S.ProjectName = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  font-size: 1.25rem;
  font-family: ${props => props.theme.fonts.accent};
  transition: all ${props => props.theme.transition} ease-in-out;
  color: ${props => props.theme.colors.background};
  text-align: center;

  ${media.mobileL`
    font-size: 1.5rem;
  `}

  ${media.laptop`
    font-size: 2rem;
    opacity: 0;
    transition: all ${props => props.theme.transition} ease-in-out;
    ${S.ProjectListItem}:hover & {
      opacity: 1;
    }
  `}
`

S.ComingSoon = styled.span`
  font-size: 1.2rem;
`

const IndexPage = () => {
  const { site, logo, defaultImage, allContentfulProjekt } = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            siteUrl
          }
        }
        logo: file(relativePath: { eq: "images/logo.png" }) {
          childImageSharp {
            gatsbyImageData(width: 200, placeholder: BLURRED, layout: FIXED)
          }
        }
        defaultImage: file(name: { eq: "homepage" }) {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              height: 630
              placeholder: BLURRED
              layout: FIXED
            )
          }
        }
        allContentfulProjekt(
          sort: { order: ASC, fields: order }
          filter: { node_locale: { eq: "pl" }, isVisible: { eq: true } }
        ) {
          edges {
            node {
              id
              name
              slug
              comingSoon
              thumbnail {
                gatsbyImage(
                  layout: CONSTRAINED
                  width: 470
                  height: 693
                  cropFocus: ENTROPY
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    `
  )

  const imageUrl =
    site.siteMetadata.siteUrl +
    getSrc(defaultImage.childImageSharp.gatsbyImageData)
  const logoUrl =
    site.siteMetadata.siteUrl + getSrc(logo.childImageSharp.gatsbyImageData)

  const jsonLdBusiness = {
    "@context": "https://schema.org",
    "@type": "HomeAndConstructionBusiness",
    "@id": site.siteMetadata.siteUrl,
    name: "Kamiko",
    description:
      "Kamiko to studio projektowe specjalizujące się w projektach wnętrz mieszkalnych i usługowych. Tworzymy projekty dopasowane do indywidualnych potrzeb klienta.",
    image: imageUrl,
    priceRange: "$$",
    address: {
      "@type": "PostalAddress",
      streetAddress: "Braniborska 2-10, pok. 215",
      addressLocality: "Wrocław",
      postalCode: "53-680",
      addressCountry: "PL",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: "51.1103451",
      longitude: "17.0164937",
    },
    hasMap:
      "https://www.google.pl/maps/place/Kamiko/@51.1103451,17.0164937,15z/data=!4m5!3m4!1s0x0:0x56d78d58ec48ad1c!8m2!3d51.1103451!4d17.0164937",
    telephone: "+48514509527",
    url: site.siteMetadata.siteUrl,
    logo: logoUrl,
    sameAs: [
      "https://www.facebook.com/halokamiko/",
      "https://www.instagram.com/kamiko.interiors/",
    ],
  }

  const jsonLdDataWebsite = {
    "@context": "http://schema.org",
    "@type": "WebSite",
    url: site.siteMetadata.siteUrl,
    name: "Kamiko",
  }

  const jsonLdBreadcrumbs = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: site.siteMetadata.siteUrl,
        name: "Kamiko",
      },
    ],
  }

  return (
    <Layout>
      <Seo
        url="/"
        jsonLdData={[jsonLdBusiness, jsonLdDataWebsite, jsonLdBreadcrumbs]}
      />
      <S.ImagesList>
        {allContentfulProjekt.edges.map(edge => (
          <S.ProjectListItem key={edge.node.id}>
            {edge.node.comingSoon ? (
              <>
                <S.ImageWrapper>
                  <GatsbyImage
                    image={edge.node.thumbnail.gatsbyImage}
                    alt={edge.node.name}
                  />
                </S.ImageWrapper>
                <S.ProjectName>
                  {edge.node.name}
                  <S.ComingSoon>
                    <br /> wkrótce
                  </S.ComingSoon>
                </S.ProjectName>
              </>
            ) : (
              <Link to={getProjectLink(edge.node.slug)}>
                <S.ImageWrapper>
                  <GatsbyImage
                    image={edge.node.thumbnail.gatsbyImage}
                    alt={edge.node.name}
                  />
                </S.ImageWrapper>
                <S.ProjectName>{edge.node.name}</S.ProjectName>
              </Link>
            )}
          </S.ProjectListItem>
        ))}
      </S.ImagesList>
    </Layout>
  )
}

export default IndexPage
